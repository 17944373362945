import React from "react";
import parse from "html-react-parser";

const MobileImageAds = ({
  index,
  isMobile,
  dataURL,
  imageAdsInserted,
  currentPage,
  article,
}) => {
  const options = {
    replace: (node) => {
      // If there is no node then do not change node
      if (!node.attribs) {
        return;
      }

      // If on desktop replace img tag but don't add any ads
      if (node.attribs && node.attribs.src && !isMobile) {
        return (
          <>
            <img
              className="code-block__image"
              src={node.attribs.src}
              alt=""
              loading={index === 0 ? "eager" : "lazy"}
              width={node.attribs.width}
              height={node.attribs.height}
            />
          </>
        );
      }

      // If on mobile and node has an image
      if (node.attribs && node.attribs.src && isMobile) {
        imageAdsInserted = true;
        //console.log(node.attribs);
        return (
          <>
            <div className="code-block code-block--banner">
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div
                id="LeaderBelowTitle"
                className="ad-container ad-container--leaderBelowTitle"
              />
            </div>
            <img
              className="code-block__image"
              src={node.attribs.src}
              alt=""
              loading={index === 0 ? "eager" : "lazy"}
              width={node.attribs.width}
              height={node.attribs.height}
            />
            <div className="code-block code-block--banner">
              <div className="divider">
                <span> Advertisement</span>
              </div>
              <div
                id="MPUTopRight"
                className="ad-container ad-container--MPUTopRight"
              />
            </div>
          </>
        );
      }

      // If all else Fails then don't change node
      return;
    },
    trim: true,
  };

  return (
    <div
      data-url={dataURL}
      data-index={index}
      style={{ display: index === currentPage ? "block" : "none" }}
    >
      {parse(article, options)}
    </div>
  );
};

export default MobileImageAds;
